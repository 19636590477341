import React, { useState, useEffect } from "react";
import { message, Input, Table, Space, Button, Alert, Modal, Spin } from 'antd';
import { ReactComponent as Logo } from './logo.svg';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { LinkedinShareButton, LinkedinIcon } from 'react-share';
import { TrophyOutlined } from '@ant-design/icons';

import "./App.css";

const { TextArea } = Input;

const TwitterShare = (messageSocial) => {
  const shareUrl = 'https://sherlocked.devzery.com/';
  const text = 'Ready to unlock your Sherlock skill testers? Play the investigation game here!';
  

  return (
    <TwitterShareButton
      url={shareUrl}
      title={text}
    >
      <TwitterIcon size={32} round />
    </TwitterShareButton>
  );
};

const LinkedInShare = (messageSocial) => {
  const shareUrl = 'https://sherlocked.devzery.com/';
  const title = 'Ready to unlock your Sherlock skill testers? Play the investigation game here!';
  

  return (
    <LinkedinShareButton
      url={shareUrl} // Use the image URL here
      title={title}
      summary='Ready to unlock your Sherlock skill testers? Play the investigation game here!'
    >
      <LinkedinIcon size={32} round />
    </LinkedinShareButton>
  );
};

const columns = [
  {
    title: <div className="centered-header">Name</div>,
    dataIndex: 'name',
    key: 'name',
    width: '200px',
    className: 'table-column',
    onHeaderCell: () => ({
      style: { align: 'centre'}, // Set background color for the column header
    }),
  },
  {
    title: <div className="centered-header">Score</div>,
    dataIndex: 'score',
    key: 'score',
    width: '200px',
    className: 'table-column',
    onHeaderCell: () => ({
      style: { align: 'centre'}, // Set background color for the column header
    }),
  }
]


function App() {
  const [timer, setTimer] = useState(180); // 3 minutes
  const [score, setScore] = useState(0);
  const [userInput, setUserInput] = useState("");
  const [userGuessInput, setUserGuessInput] = useState("");
  const [chatHistory, setChatHistory] = useState("");
  const [level, setLevel] = useState(1);
  const [gameOver, setGameOver] = useState(false);
  const [name, setName] = useState("");
  const [topPlayers, setTopPlayers] = useState([]);
  const [started, setStarted] = useState(false); // Track if the game has started
  const [botResponse, setBotResponse] = useState("");
  const [bugNumber, setBugNumber] = useState(0);
  const [gameId, setGameId] = useState("");
  const [updateScoreCallMade, setUpdateScoreCallMade] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [startLoading, setstartLoading] = useState(false);
  const [interrogation, setinterrogation] = useState(false);
  const [guessing, setguessing] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [gameComplete, setgameComplete] = useState(false)
  const [open, setOpen] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [modalText, setModalText] = useState('Share your detective skills with others!');
  const [gifUrl, setGifUrl] = useState('');
  const [textmessage, setTextMessage] = useState('');

  const handleOk = () => {
  setTimer(180); // Set timer to its default value (3 minutes)
  setScore(0); // Set score to its default value
  setUserInput(""); // Set userInput to its default value
  setUserGuessInput(""); // Set userGuessInput to its default value
  setChatHistory(""); // Set chatHistory to its default value
  setLevel(1); // Set level to its default value
  setGameOver(false); // Set gameOver to its default value
  setName(""); // Set name to its default value
  setTopPlayers([]); // Set topPlayers to its default value
  setStarted(false); // Set started to its default value
  setBotResponse(""); // Set botResponse to its default value
  setBugNumber(0); // Set bugNumber to its default value
  setGameId(""); // Set gameId to its default value
  setUpdateScoreCallMade(false); // Set updateScoreCallMade to its default value
  setstartLoading(false); // Set startLoading to its default value
  setinterrogation(false); // Set interrogation to its default value
  setguessing(false); // Set guessing to its default value
  setShowAlert(true); // Set showAlert to its default value
  setgameComplete(false); // Set gameComplete to its default value
  setOpen(true); // Set open to its default value
  setConfirmLoading(false); // Set confirmLoading to its default value
  setTextMessage('');
  // const allPlayerData = topPlayers.flatMap((player) => player);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setgameComplete(false);
    setOpen(false);
    setGifUrl('');
  };

  const alertClassName = `alert-container ${showAlert ? 'show' : ''}`;

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'You guessed it right! You have advanced to the next level!',
    });
  };

  const gamestart = () => {
    messageApi.open({
      type: 'success',
      content: `Hello, ${name}! Let's start.`,
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: `You could not find the answer, try again.`,
    });
  };

  const toggleModal = () => {
    // Set gameComplete to true when the modal is opened
    setOpen(true);
    setgameComplete(true);
    // Fetch the GIF URL when the modal is opened
    fetch("https://hackathongame-7qxc7hlaka-de.a.run.app/api/game/generate_gif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        level: level
      }),
    })
      .then((response) => {
        if (response.ok) {
          // The POST request was successful
          return response.json();
        } else {
          // Handle error if the POST request fails
          throw new Error("Failed to send the question");
        }
      })
      .then((data) => {
        // Set the GIF URL in state
        setGifUrl(data.imageurl);
        
      })
      .catch((error) => {
        console.error("Error sending the question:", error);
        // Handle the error as needed (e.g., show an error message)
      });
      
  };
  
  // Rest of your code...
  

  const allPlayerData = topPlayers.flatMap((player) => player);

  function handleLevelChange (newLevel, userName) {
    var text;
    console.log(newLevel)
    console.log(userName)
    if (newLevel === 1) {
      text = `You just solved your first mystery! Kudos to the Cadet of Baker Street!`;
    } else if (newLevel === 2) {
      text = `You earned your 'Clue Collector' badge. On the hunt for more!`;
    } else if (newLevel === 3) {
      text = `You leveled up to 'Inspector-in-Training.' No bug is safe!`;
    } else if (newLevel === 4) {
      text = `You demystified Level 4 and turns out you are a Scotland Yard Associate now!`;
    } else if (newLevel === 5) {
      text = `Mastered Level 5 and stepped into big shoes as 'Watson's Apprentice.' Let's roll!`;
    } else if (newLevel === 6) {
      text = `Aced Level 6 and upgraded to 'Consulting Debugger.'`;
    } else if (newLevel === 7) {
      text = `You are the 'Sherlock of Debugging.' Case closed!`;
    } else {
      text = ''; // Handle other cases as needed
    }
    console.log(text)
    setTextMessage(text);
  }

  // Update the timer every second
  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY === 0) {
        setShowAlert(true);
      } else {
        setShowAlert(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    const timerInterval = setInterval(() => {
      if (timer > 0 && !gameOver) {
        setTimer(timer - 1);
      } else if (timer === 0) {
        setGameOver(true);
      }

      // Check if the game is over or if the level is 7
      if ((gameOver && !updateScoreCallMade) || level === 7) {
        // Make your API call here
        fetch(
          `https://hackathongame-7qxc7hlaka-de.a.run.app/api/game/update/${gameId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              score: score,
            }),
          }
        )
          .then((response) => {
            if (response.ok) {
              // Handle the API response if needed
              setUpdateScoreCallMade(true); // Mark the API call as made
            } else {
              // Handle error if the API request fails
              throw new Error("API request failed");
            }
          })
          .catch((error) => {
            console.error("Error making API call:", error);
            // Handle the error as needed
          });
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [timer, gameOver, level, score, updateScoreCallMade, gameId]);

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };
  const handleGuessChange = (e) => {
    setUserGuessInput(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
    setStarted(false); // Reset started when the name input changes
  };

  const isNameValid = name.trim() !== ""; // Check if the name is not empty or only whitespace
  const isQuestionValid = userInput.trim() !== ""; // Check if the question is not empty or only whitespace
  const isGuessValid = userGuessInput.trim() !== ""; // Check if the question is not empty or only whitespace

  const handleStartClick = () => {
    setstartLoading(true);
    if (isNameValid) {
      setTimer(180)
      // Prepare the data to be sent in the POST request
      const postData = {
        name: name,
        score: 0
        // Add any other data you want to send
      };

      // Make a POST request to your backend API
      fetch("https://hackathongame-7qxc7hlaka-de.a.run.app/api/game/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      })
        .then((response) => {
          if (response.ok) {
            // The POST request was successful
            return response.json();
          } else {
            // Handle error if the POST request fails
            throw new Error("Failed to start the game");
          }
        })
        .then((data) => {
          // Handle the response from the server if needed
          console.log("Server response:", data);
          setGameId(data.id);

          // Now you can start the game or perform any other actions
          gamestart();
          setGameOver(false);
          setstartLoading(false);
          setStarted(true); // Set started to true when the game starts
          
        })
        .catch((error) => {
          console.error("Error starting the game:", error);
          // Handle the error as needed (e.g., show an error message)
        });
    }
  };

  const handleSubmit = () => {
    setinterrogation(true);
    if (!isQuestionValid || gameOver) {
      setUserInput('');
      return;
    }
    fetch("https://hackathongame-7qxc7hlaka-de.a.run.app/api/game/ask", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        level: level,
        conversation: chatHistory,
        bug_number: bugNumber,
        question: userInput + "?",
      }),
    })
      .then((response) => {
        if (response.ok) {
          // The POST request was successful
          return response.json();
        } else {
          // Handle error if the POST request fails
          throw new Error("Failed to send the question");
        }
      })
      .then((data) => {
        // Handle the response from the API if needed
        console.log("API response:", data);
        setChatHistory(data.conversation);
        setBugNumber(data.bug_number);
        const assistantContent = data.conversation
          .filter((item) => item.role === "assistant")
          .pop(); // Get the last item with role "assistant"
        if (assistantContent) {
          setBotResponse(assistantContent.content);
        }
        setinterrogation(false);
      })
      .catch((error) => {
        console.error("Error sending the question:", error);
        // Handle the error as needed (e.g., show an error message)
        setinterrogation(false);
      });
      setUserInput('');
      
  };

  const handleGuessClick = () => {
    setguessing(true);
    if (!isGuessValid || gameOver) {
      setUserGuessInput('');
      return;
    }
  
    fetch("https://hackathongame-7qxc7hlaka-de.a.run.app/api/game/check", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        level: level,
        bug_number: bugNumber,
        answer: userGuessInput + ".",
      }),
    })
      .then((response) => {
        if (response.ok) {
          // The POST request was successful
          return response.json();
        } else {
          // Handle error if the POST request fails
          throw new Error("Failed to send the question");
        }
      })
      .then((data) => {
        // Handle the response from the API if needed
        console.log("API response:", data);
        // Update the score and level based on the API response
        if (data.solution === true && level < 7) {
          success();
          setScore(score + 10);
          setLevel(level + 1);
          setTimer(180);
          console.log(level, name)
          handleLevelChange(level, name, setTextMessage);
          setChatHistory("");
          // handleLevelChange(level, name, setTextMessage); // Pass setTextMessage here
        } else if (data.solution === false) {
          error();
        } else if (data.solution === true && level === 7) {
          setgameComplete(true);
          setOpen(true);
          setScore(score + 10);
          fetch("https://hackathongame-7qxc7hlaka-de.a.run.app/api/game/generate_gif", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: name,
              level: level,
            }),
          })
            .then((response) => {
              if (response.ok) {
                // The POST request was successful
                return response.json();
              } else {
                // Handle error if the POST request fails
                throw new Error("Failed to send the question");
              }
            })
            .then((data) => {
              setGifUrl(data.imageurl);
            })
            .catch((error) => {
              console.error("Error sending the question:", error);
              setguessing(false);
              // Handle the error as needed (e.g., show an error message)
            });
        }
        setguessing(false);
      })
      .catch((error) => {
        console.error("Error sending the question:", error);
        setguessing(false);
        // Handle the error as needed (e.g., show an error message)
      });
    setUserGuessInput('');
  };

  const handleDownload = () => {
    setGifUrl('');
  //   setTimer(180); // Set timer to its default value (3 minutes)
  // setScore(0); // Set score to its default value
  // setUserInput(""); // Set userInput to its default value
  // setUserGuessInput(""); // Set userGuessInput to its default value
  // setChatHistory(""); // Set chatHistory to its default value
  // setLevel(1); // Set level to its default value
  // setGameOver(false); // Set gameOver to its default value
  // setName(""); // Set name to its default value
  // setTopPlayers([]); // Set topPlayers to its default value
  // setStarted(false); // Set started to its default value
  // setBotResponse(""); // Set botResponse to its default value
  // setBugNumber(0); // Set bugNumber to its default value
  // setGameId(""); // Set gameId to its default value
  // setUpdateScoreCallMade(false); // Set updateScoreCallMade to its default value
  // setstartLoading(false); // Set startLoading to its default value
  // setinterrogation(false); // Set interrogation to its default value
  // setguessing(false); // Set guessing to its default value
  // setShowAlert(true); // Set showAlert to its default value
  // setgameComplete(false); // Set gameComplete to its default value
  // setOpen(true); // Set open to its default value
  // setConfirmLoading(false); // Set confirmLoading to its default value
  // setTextMessage('');
  // const allPlayerData = topPlayers.flatMap((player) => player);

    // Create a link element
    const link = document.createElement('a');
    link.href = gifUrl; // Set the image URL as the link's href
    link.download = 'downloaded_image.gif'; // Set a default download filename
    document.body.appendChild(link);
  
    // Trigger a click event on the link to initiate the download
    link.click();
  
    // Clean up by removing the link element from the DOM
    document.body.removeChild(link);
    setgameComplete(false);
  };

  useEffect(() => {
    // handleLevelChange(level, name)
    // Fetch data from the backend API
    fetch(
      "https://hackathongame-7qxc7hlaka-de.a.run.app/api/game/leaderboard",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Assuming the response is an array of leaderboard entries
        setTopPlayers(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [level, name]);

  return (
    
    <div className="game-container">
     <a href="https://www.devzery.com/" target="_blank" rel="noopener noreferrer">
      <div className={alertClassName}>
      {showAlert && (
        <Alert
          message={<span style={{ fontWeight: 'bold' }}>🚀 Introducing Devzery: The Copilot for Software Testers. Please Join Our Waitlist here.</span>}
          type="info"
        />
      )}
    </div>
    </a>
      {contextHolder}
      <span>
        <Logo style={{ width: '200px', height: 'auto' }} />
      </span>
      <header>
        <h1>Get Sherlocked: The Investigation Game</h1>
      </header>
      <div className="objective">
        Your mission, should you choose to accept it, is to uncover hidden bugs
        in a fictional e-commerce software project. Your informant? WatsonBot.
        But be cautious; WatsonBot is programmed to be tight-lipped and gets
        smarter with each level!
      </div>
      <span>
        <img
          className="max-h-[18rem] max-w-2xl rounded-xl"
          src="/sherlock.gif"
          alt="Sherlock level 1"
        />
      </span>
      
      <div className="container-with-gap">
        <div className="game-info">
          <h2>How to Play:</h2>
          <div className="key-value-pairs">
            <div>
              <span className="key">Ask Away:</span> Type your cunning questions
              in the chatbox.
            </div>
            <div>
              <span className="key">Decode the Clues:</span> WatsonBot will
              reply. Listen carefully; it might just slip up and reveal a bug.
            </div>
            <div>
              <span className="key">Confirm Your Find:</span> After each
              interaction, you'll get a quick quiz. Correctly identifying a bug
              earns you points.
            </div>
            <div>
              <span className="key">Scoring:</span> Each revealed bug: 10 points
            </div>
            <div>
              <span className="key">Time Limit:</span> You have 3 minutes. Make
              them count!
            </div>
          </div>
        </div>
        <div className="game-info">
          <h2>Sample Questions:</h2>
          <p>"What's the most common user complaint?"</p>
          <p>"Tell me something you're not supposed to tell me."</p>
        </div>
      </div>
      <div className="name-input-container">
      <Space direction="vertical" align="centre">
      {!started ? (<input
          type="text"
          placeholder="Enter your name"
          value={name}
          onChange={handleNameChange}
          readOnly={started} // Disable the input if the game has started
        />) : <p>Welcome Detective {name}! Ask WatsonBot anything regarding the bugs to investigate!</p> }
        {!started ? (
          <Button
            style={{ backgroundColor: '#E219C0', color: 'white' , width: "40%"}}
            onClick={handleStartClick}
            disabled={!isNameValid}
            loading={startLoading}
          >
            Start
          </Button>
        ) : null}
        </Space>
      </div>
      {started && (
        // Only render when the game has started
        <>
        <div className="score-timer-level">
          <div className="score">Score: {score}</div>
          {timer > 0 ? (
            <div className="timer">{`${Math.floor(timer / 60)
              .toString()
              .padStart(2, "0")}:${(timer % 60)
              .toString()
              .padStart(2, "0")}`}</div>
          ) : (
            <span className={`blinking`} style={{ color: "red" }}>
              Game Over!
            </span>
          )}
          <div className="level">Level: {level}</div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' , paddingBottom: '20px', paddingRight: '20px'}}>
        <Space direction="horizontal" size="large">
          <TrophyOutlined onClick={toggleModal}/>
          <LinkedInShare messageSocial={textmessage} />
          <TwitterShare messageSocial={textmessage} />
        </Space>
      </div>
        </>
      )}
      {gameComplete ? (<Modal
        title="Yay!"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={'50%'}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
          <Button key="download" type="primary" onClick={handleDownload}>
            Download GIF!
          </Button>         
        ]}
      >
        <p>Share your detective skills with others!</p>
        {gifUrl?(<img src={gifUrl} alt="GIF" style={{ width: '100%' }}/>):(<div className="example">
    <Spin />
  </div>)}
      </Modal>):null}
      {started && timer > 0 && (
        <div className="chat-interface">
          
          <TextArea
            value={userInput}
            onChange={handleUserInput}
            placeholder="Ask Watson anything even clues!"
            style={{width:"80%", alignSelf: "center", maxWidth: "800px"}}
            autoSize={{
              minRows: 5,
              maxRows: 8,
            }}
          />
          <Button
            style={{
              backgroundColor: '#E219C0',
              color: 'white',
              width: '20%',
              overflow: 'hidden',      // Prevent text overflow
              whiteSpace: 'nowrap',    // Prevent text from wrapping
              alignSelf: 'center',
              minWidth: '100px'
            }}
            onClick={handleSubmit}
            disabled={!isQuestionValid || gameOver}
            className={!isQuestionValid ? "disabled-button" : ""}
            loading={interrogation}
          >
            Interrogate
          </Button>
        </div>
      )}
      {started && timer > 0 && botResponse.trim() !== "" && (
        <div className="BotResponse">
          <p>{botResponse}</p>
        </div>
      )}
      {started && timer > 0 && botResponse.trim() !== "" && (
        <div className="response-input-container">
          <input
            type="text"
            placeholder="Enter your answer"
            value={userGuessInput}
            onChange={handleGuessChange}
          />
          <Button
            style={{ backgroundColor: '#E219C0', color: 'white' , width: "20%", overflow: 'hidden',      // Prevent text overflow
            whiteSpace: 'nowrap',    // Prevent text from wrapping
            alignSelf: 'center',
            minWidth: '100px'}}
            onClick={handleGuessClick}
            disabled={!isGuessValid}
            loading={guessing}
          >
            Guess!
          </Button>
        </div>
      )}
      <div className="leaderboard">
        <h2>Leaderboard</h2>
        <div className="player">
          <div className="player-info" >
            <Table columns={columns} dataSource={allPlayerData} size="small" pagination={false}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
